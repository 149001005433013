import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import ModalSimple from '@solid-ui-blocks/Modal/Block02'
import Header from '@solid-ui-blocks/Header/Block01'
import Terms from '@solid-ui-blocks/Features/Block06'
import Cond1 from '@solid-ui-blocks/Companies/Block03'
import Cond from '@solid-ui-blocks/Companies/Block02'
import Cond2 from '@solid-ui-blocks/Companies/Block01'
import Cond3 from '@solid-ui-blocks/Companies/Block01'
import Footer from '@solid-ui-blocks/Footer/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import theme from './_theme'

const IndexPage = props => {
  const { allBlockContent } = useStaticQuery(query)
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout theme={theme} {...props}>
      <Seo title='Home' />
      {/* Modals */}
      <ModalWithTabs content={content['authentication']} reverse />
      <ModalWithTabs content={content['contact']} />
      <ModalSimple content={content['advertisement']} />
      {/* Blocks */}
      <Header content={content['headerTC']} />
      <Divider space='5' />
      <Divider space='5' />
      <Terms content={content['terms-and-conditions']} />
      <Cond1 content={content['no-warranty']} />
      <Cond content={content['no-warranty2']} />
      <Cond2 content={content['limitation-of-liability']} />
      <Cond3 content={content['indemnity']} />
      {/* <Container variant='full' sx={styles.heroContainer}>
        <Hero content={content['hero']} />
      </Container> */}
      {/* <Divider space='5' />
      <Features content={content['features']} />
      <Divider space='5' />
      <Divider space='5' />
      <Container variant='wide' sx={styles.featuresContainer}>
        <FeatureTabOne content={content['feature-tab-one']} reverse />
        <Divider space='5' />
        <Divider space='5' />
        <FeatureTabTwo content={content['feature-tab-two']} />
        <Divider space='5' />
        <Divider space='5' />
        <FeatureTabThree content={content['feature-tab-three']} reverse />
      </Container>
      <Divider space='5' />
      <Divider space='5' />
      <WhyChooseUs content={content['why-choose-us']} />
      <Divider space='5' />
      <Divider space='5' />
      <Pricing content={content['pricing']} />
      <Divider space='5' />
      <Divider space='5' />
      <Container variant='full' sx={styles.whyContainer}>
        <Testimonials content={content['testimonials']} />
      </Container>
      <Companies content={content['companies']} />
      <Divider space='5' />
      <Strategies content={content['get-started']} />
      <Divider space='5' />
      <Divider space='5' />
      <Blog content={content['latest-blogs']} />
      <Divider space='5' />
      <Divider space='5' /> */}
      <Footer content={content['footer']} />
    </Layout>
  )
}

const query = graphql`
  query sirrutermsandconditionsBlockContent {
    allBlockContent(filter: { page: { in: ["site/terms-and-conditions", "site/shared"] } }) {
      nodes {
        ...BlockContent
      }
    }
  }
`
export default IndexPage
