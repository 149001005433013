import React from 'react'
import SVG from 'react-inlinesvg'
import { Container, Flex, Box, css } from 'theme-ui'
import ContentText from '@solid-ui-components/ContentText'
import Reveal from '@solid-ui-components/Reveal'
import StackTitle from '@solid-ui-components/StackTitle5'
import ContentButtons from '@solid-ui-components/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

const styles = {
  logo: {
    width: [100, 150],
    height: [50, 75]
  }
}

const CompaniesBlock02 = ({ content }) => {
  const { kicker, title, text_1, text_2, collection, buttons } = content

  return (
    <Container sx={{ textAlign: `center` }}>
      <StackTitle kicker={kicker} title={title} lines={[text_1, text_2]} />
      <Flex sx={{ flexWrap: `wrap`, m: -3 }}>
        {collection?.map(({ title, icon }, index) => (
          <Box key={`item-${index}`} sx={{ flexGrow: 1, p: [1, 3] }}>
            <Reveal
              effect='fadeInGrow'
              delay={0.2 * (index + 2)}
              threshold={1}
              title={title?.text}
            >
              {icon?.src ? (
                <SVG src={icon.src} css={css(styles.logo)} />
              ) : (
                <ContentText content={title} variant='h6' mb='0' />
              )}
            </Reveal>
          </Box>
        ))}
      </Flex>
      <ContentButtons content={buttons} space={3} />
    </Container>
  )
}

export default WithDefaultContent(CompaniesBlock02)
